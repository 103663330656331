import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { ModalBody, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import { ArticleHeader2 } from '../../../components/parent/article-header'
import ArticleBGWrapper from '../../../components/parent/article-bg-wrapper'
import UsefulTools from '../../../components/parent/useful-tools'
import {
  CuriosityCloseButton,
  CuriosityModal,
} from '../../../components/parent/curiosity'

import { ArticleWrapper, ArticleContainer } from '../../../components/elements'

const ExpertWrapper = styled.div`
  margin-bottom: 45px;

  .gatsby-image-wrapper {
    height: auto;
    max-width: 125px;
  }

  p {
    font-weight: 400;
    margin-top: 5px;
  }
`

export const Expert = ({ image, name }) => (
  <ExpertWrapper>
    <Img fluid={image} alt={name} />
    <p>{name}</p>
  </ExpertWrapper>
)

const Page = ({ location }) => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/payback/article4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/krzyzowki.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 1390) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool3: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440, maxHeight: 1342) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImage: file(
        relativePath: { eq: "parent/curiosity/DESKTOP/desktop_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      CuriosityBigImageMobile: file(
        relativePath: { eq: "parent/curiosity/MOBILE/mobile_3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Expert: file(relativePath: { eq: "parent/payback/expert4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 125) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Czy dawanie kieszonkowego się opłaca? Natalia Tur"
        description="Mogłoby się wydawać, że dzieci i pieniądze to dwa odległe światy. Jeśli łączymy je w jakikolwiek sposób to najczęściej wtedy, kiedy uświadamiamy sobie, ile nas kosztuje utrzymanie dziecka lub kiedy komentujemy zgłaszane przez dziecko potrzeby finansowe."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader2
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        title={<>Czy dawanie kieszonkowego się opłaca?</>}
        linkPrev="/strefa-rodzica/kieszonkowe"
      />

      <ArticleBGWrapper>
        <ArticleWrapper style={{ marginTop: 0, fontWeight: 300 }}>
          <ArticleContainer>
            <Row>
              <Col xs={12} className="marginTop30">
                <Expert
                  image={data.Expert.childImageSharp.fluid}
                  name="Natalia Tur"
                />

                <p>
                  Moim zdaniem zdecydowanie tak. Każde z{'\u00A0'}trójki moich
                  dzieci otrzymuje kieszonkowe, łącznie z{'\u00A0'}najmłodszym
                  5,5-latkiem. Uważam, że nie ma{'\u00A0'}lepszego sposobu, by
                  {'\u00A0'}nauczyć dziecko mądrego dysponowania własnym
                  budżetem, niż kieszonkowe.
                </p>
                <p>
                  Regularne otrzymywanie stałej kwoty na{'\u00A0'}drobne wydatki
                  jest jak przedsmak wypłaty, umożliwia zaplanowanie zakupów i
                  {'\u00A0'}ewentualnych oszczędności w{'\u00A0'}określonym
                  czasie oraz wymusza ustalenie zakupowych priorytetów.{' '}
                </p>
                <p>
                  Dziecko może sobie wyliczyć, ile pieniędzy może przeznaczyć na
                  {'\u00A0'}finansowanie swoich zachcianek, a{'\u00A0'}kiedy
                  zacząć oszczędzać, by{'\u00A0'}np. móc kupić sobie coś, o
                  {'\u00A0'}czym marzyło. Samochodzik dla kilkulatka czy
                  słuchawki dla nastolatki kupione za{'\u00A0'}własne pieniądze
                  to{'\u00A0'}wielka radość, satysfakcja i{'\u00A0'}duma.
                  Obserwując moje dzieci, miałam wrażenie, że dysponowanie
                  swoimi pieniędzmi wzmacniało ich poczucie autonomii i
                  {'\u00A0'}sprawczości oraz uczyło je{'\u00A0'}cierpliwości i
                  {'\u00A0'}wytrwałości. Wierzę też, że nauka rozsądnego
                  zarządzania pieniędzmi jest dobrą praktyką, która może im
                  {'\u00A0'}się przydać w{'\u00A0'}przyszłości. Mam więc
                  poczucie, że wyposażam je{'\u00A0'}w cenną umiejętność.
                </p>
                <p>
                  Zauważyłam też, że dysponowanie swoimi pieniędzmi motywowało
                  moje nastolatki do{'\u00A0'}wyjścia z{'\u00A0'}domu i
                  {'\u00A0'}spotkań towarzyskich. To{'\u00A0'}potwierdza się też
                  w{'\u00A0'}badaniach – aż 83% respondentów w{'\u00A0'}wieku od
                  {'\u00A0'}8. do 18. r.ż. przyznało, że własne pieniądze dają
                  im{'\u00A0'}poczucie swobody.
                </p>
                <p>
                  Nie ukrywam też, że fakt, że moje dzieci otrzymują
                  kieszonkowe, ułatwia mi{'\u00A0'}kontrolowanie rodzinnego
                  budżetu, a{'\u00A0'}mówiąc wprost – asertywne ucinanie
                  ciągłego proszenia latorośli o{'\u00A0'}pieniądze. Zawsze mogę
                  odpowiedzieć: masz kieszonkowe, zarządzaj nim! :)
                </p>
              </Col>
            </Row>
          </ArticleContainer>
        </ArticleWrapper>

        <UsefulTools
          items={[
            {
              title: 'Ciekawostki',
              subtitle:
                'Rozmowy dzieci z dorosłymi o pieniądzach mają wielką wartość, czyli raport PISA',
              image: data.Tool3,
              onClick: toggleModal,
            },
            {
              title: 'Kolorowanki i rebusy',
              subtitle: 'Kolorowanka',
              image: data.Tool2,
              link: '/parent/Kolorowanka%201.pdf',
            },
            {
              title: 'Gry',
              subtitle: 'Bankosłówka',
              image: data.Tool1,
              link: '/parent/Bankoslowka.pdf',
            },
          ]}
        />
      </ArticleBGWrapper>

      <CuriosityModal isOpen={modal} size="lg" toggle={toggleModal}>
        <ModalBody>
          <CuriosityCloseButton onClick={toggleModal} />
          <div />
          <Img
            fluid={data.CuriosityBigImage.childImageSharp.fluid}
            className="curiosityImage"
          />
          <Img
            fluid={data.CuriosityBigImageMobile.childImageSharp.fluid}
            className="curiosityImageMobile"
          />
        </ModalBody>
      </CuriosityModal>
    </Layout>
  )
}

export default Page
